export default () => ({
  expanded: {} as { [itemId: string]: boolean },
  toggle(itemId: string) {
    this.expanded[itemId] = !this.expanded[itemId];
  },
  expandAll() {
    for (const itemId in this.expanded) {
      this.expanded[itemId] = true;
    }
  },
  collapseAll() {
    for (const itemId in this.expanded) {
      this.expanded[itemId] = false;
    }
  },
  get allCollapsed() {
    for (const itemId in this.expanded) {
      if (this.expanded[itemId]) {
        return false;
      }
    }
    return true;
  },
  get allExpanded() {
    for (const itemId in this.expanded) {
      if (!this.expanded[itemId]) {
        return false;
      }
    }
    return true;
  },
});
