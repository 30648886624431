import 'htmx.org';
import Alpine from 'alpinejs';

import { expandables } from '../alpine';

import Messages from '../messages';

const initializePage = () => {
  // @ts-ignore
  window.messages = new Messages();

  Alpine.data('expandables', expandables);
  Alpine.start();
};

export { initializePage };
