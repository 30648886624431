export default class Messages {
  showMessage(message: string) {
    this.createMessageItem(message);
  }

  showSuccess(message: string) {
    this.createMessageItem(message, 'success');
  }

  showError(message: string) {
    this.createMessageItem(message, 'error');
  }

  private createMessageContainer() {
    const messages = document.createElement('ul');
    messages.classList.add('messages', 'pl-xxxxs');
    document.body.appendChild(messages);
    return messages;
  }

  private createMessageItem(message: string, status: 'success' | 'error' | null = null) {
    let messages = document.querySelector('.messages');
    if (messages === null) {
      messages = this.createMessageContainer()
    }

    const li = document.createElement('li');
    li.innerText = message;
    li.classList.add(
      'mb-xxxxs',
      'mr-xxxxs',
      'bg--color-black',
      'text--color-white',
      'box--radius-small',
      'p-xxxxs'
    );

    messages.appendChild(li);

    let colorClass;
    if (status !== null) {
      colorClass = 'bg--color-status-' + status;
    } else {
      colorClass = 'bg--color-primary';
    }
    li.classList.add(colorClass);
  }
}
